import { create } from 'zustand';

const useWebSocketStore = create((set) => ({
    wsUrl: 'wss://mmhxspsmbrhpdfae.tunnel-pt.elice.io/ws',
    ws: null,
    setWebSocket: (wsInstance) => set({ ws: wsInstance }),
    setWsUrl: (wsUrl) => set({ wsUrl }),
}));


export default useWebSocketStore;