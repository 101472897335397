import { create } from 'zustand';

const useHLSIPStore = create((set) => ({
    HLSIP: 'https://mmhxspsmbrhpdfae.tunnel-pt.elice.io',
    playBackSpeed: 0.6,
    setHLSIP: (HLSIP) => set({ HLSIP }),
    setPlayBackSpeed: (playBackSpeed) => set({ playBackSpeed }),
}));

// 상태와 설정 함수들을 함께 반환하는 훅
const useHLSIP = () => useHLSIPStore((state) => ({
    HLSIP: state.HLSIP,
    setHLSIP: state.setHLSIP,
}));

const usePlayBackSpeed = () => useHLSIPStore((state) => ({
    playBackSpeed: state.playBackSpeed,
    setPlayBackSpeed: state.setPlayBackSpeed,
}));

export { useHLSIP, usePlayBackSpeed };
