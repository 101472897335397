import styles from "../../../css/style.module.css";
import  useDroneStore  from "../../../store/droneStore";

const Header = () => {
    const {start } = useDroneStore();

    return (
        <header className={`${styles.relative} ${styles.hidden} ${styles['w-full']} ${styles['items-end']} ${styles['justify-between']} ${styles['bg-top-layer']} ${styles['text-primary']} ${styles['md:sticky']} ${styles['md:top-0']} ${styles['md:order-1']} ${styles['md:flex']} ${styles['transition-[height]']} ${styles['duration-300']} ${styles['ease-out']} ${styles['z-20']} ${styles['md:h-14']}`}>
        <div className={`${styles.relative} ${styles.flex} ${styles['h-14']} ${styles['flex-1']} ${styles['items-center']} ${styles['justify-between']} ${styles['gap-2']} ${styles['border-b']} ${styles['border-b-wash']} ${styles['px-4']}`}>
            <div className={`${styles.invisible} ${styles['h-6']} ${styles['w-6']}`} />
            <div className={styles.relative}>
                <div className={`${styles.flex} ${styles['h-8']} ${styles['items-center']} ${styles['gap-2']} ${styles['rounded-md']} ${styles['px-2.5']}`}>
                    <p className={styles['text-subtitle-lg']}>2024 드론 봇 챌린지 본선, 고흥 활주로</p>
                    {
                        start ? 
                    <div style={{color: 'white'}} className={`${styles.flex} ${styles['h-6']} ${styles['items-center']} ${styles['justify-center']} ${styles.rounded} ${styles['bg-live']} ${styles['px-2']} ${styles['text-subtitle-md']} ${styles['m']}`}>
                        Live
                    </div>
                    : <div style={{color: 'white', backgroundColor: 'black  '}} className={`${styles.flex} ${styles['h-6']} ${styles['items-center']} ${styles['justify-center']} ${styles.rounded}  ${styles['px-2']} ${styles['text-subtitle-md']} ${styles['m']}`}>
                        Offline
                    </div>
                    }
                </div>
            </div>
            <div className={`${styles.relative} ${styles['h-6']} ${styles['w-6']} ${styles.invisible} ${styles['pointer-events-none']}`} />
        </div>
    </header>
    );
    }


export default Header;