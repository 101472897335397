import { Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage/HomePage';
import { NavermapsProvider } from 'react-naver-maps';

const App = () => {
    return (
        <>
            <NavermapsProvider ncpClientId='w0wktm26ku'>
                <Routes>
                <Route path="/" element={<HomePage />} />
                </Routes>
            </NavermapsProvider>
        </>
    );
}

export default App;