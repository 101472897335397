import React, { useEffect, useRef } from 'react';
import Hls from 'hls.js';

const HlsPlayer = ({ src, type, playbackRate }) => {
    const videoRef = useRef(null);

    useEffect(() => {
        const videoElement = videoRef.current;

        if (type === 'm3u8' && Hls.isSupported()) {
            const hls = new Hls();
            hls.loadSource(src);
            hls.attachMedia(videoElement);
            hls.on(Hls.Events.FRAG_BUFFERED, function() {
                videoElement.playbackRate = playbackRate;
            });
        } 
    }, [src, type, playbackRate]);
    // const videoElement = videoRef.current;
    // if (videoElement) {
    //     videoElement.playbackRate = 0.5;
    // }

    return type === 'm3u8' ? (
        <video ref={videoRef} controls autoPlay style={{ width: '100%', height: 'auto', borderRadius: '6px'}} />
    ) : (
        <video ref={videoRef} src={src} controls autoPlay style={{ width: '100%', height: 'auto' }} />
    );
};

export default HlsPlayer;
