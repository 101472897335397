import HlsPlayer from "../../components/HlsPlayer";
import MyMap from "../../components/navermap";
import styles from "../../css/style.module.css";
import { MessageList } from "./components/message";
import { Lists } from "./components/listcomponent";
import { useDarkModeStore } from "../../store/darkmodeStore";
import Header from "./components/header";
import airfoceLogo from "../../imgs/airforce.png";
import SettingComponent from "./components/popup/settings";


import WebSocketComponent from "../../components/websocket";
import { Container as MapDiv } from 'react-naver-maps'
import { MdOutlineDarkMode } from "react-icons/md";

import { useHLSIP, usePlayBackSpeed } from "../../store/HLSIPStore";

const HomePage = () => {
    const { darkMode, setDarkMode } = useDarkModeStore();
    const { HLSIP } = useHLSIP();
    const { playBackSpeed } = usePlayBackSpeed();

    // const { droneStore, setDroneStore } = useDroneStore();
    // const { objectStore, setObjectStore } = useObjectStore();

    return (
        <>
            <WebSocketComponent />
            <main className={`${darkMode ? styles['editor_dark'] : styles['editor']} ${styles['flex']} ${styles['h-[100svh]']} ${styles['flex-col']} ${styles['bg-primary']} ${styles['md:min-h-full']} ${styles['md:flex-row']} `}>
                <div className={`${styles['hide-scrollbar']} ${styles.flex} ${styles['flex-1']} ${styles['flex-col']} ${styles['overflow-y-auto']}`}>
                    <div className={`${styles.flex} ${styles['flex-1']} ${styles['md:overflow-hidden']}`}>
                        <div className={`${styles.hidden} ${styles['md:flex']}`}>
                            <div className={`${styles.flex} ${styles['w-full']} ${styles['flex-col']} ${styles['rounded-t-2xl']} ${styles['border-r-wash']} ${styles['bg-primary']} ${styles['px-4']} ${styles['pb-8']} ${styles['md:w-64']}  ${styles['md:rounded-none']} ${styles['md:border-r']} ${styles['pl-4']} ${styles['pr-4']} ${styles['py-2']} ${styles['md:pb-0']}`}>
                                <div className={`${styles.flex} ${styles['flex-col']}  ${styles['items-start']}`}>
                                    <img src={airfoceLogo} style={{ width: '75%' }} alt="iRASC Detection Dashboard" />
                                </div>
                                <div className={`${styles['hide-scrollbar']} ${styles.flex} ${styles['w-full']} ${styles['flex-1']} ${styles['flex-col']} ${styles['gap-1']} ${styles['overflow-y-scroll']}`}>
                                    <p className={`${styles.sticky} ${styles['top-0']} ${styles['bg-primary']} ${styles.py4} ${styles['text-subtitle-md']} ${styles['text-secondary']} ${styles['py-4']}`}>
                                        탐지된 목록
                                    </p>
                                    <Lists />
                                </div>
                                <div className={`${styles['flex']} ${styles['flex-raw']} ${styles['items-start']} ${styles['justify-between']}`}>
                                    <SettingComponent />
                                    <button style={{ width: "15%" }} className={`${styles['flex']} ${styles['items-center']} ${styles['justify-center']} ${styles['gap-3']} ${styles['rounded']} ${styles['duration-75']} ${styles['ease-out']} ${styles['border']} ${styles["border-primary/[.13]"]} ${styles['bg-primary']} ${styles['text-primary']} ${styles['shadow-sm']} ${styles['enabled:hover:bg-wash']} ${styles['text-subtitle-md']} ${styles['h-8']} ${styles['my-3']}`}
                                        onClick={() => setDarkMode(!darkMode)}>
                                        {/* > */}
                                        <MdOutlineDarkMode />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className={` ${styles.flex} ${styles['max-w-full']} ${styles['flex-1']} ${styles['flex-col']} ${styles['items-center']} ${styles['bg-top-layer']} `} style={{}}>
                            <div className={`${styles.flex} ${styles['w-full']} ${styles['flex-1']} ${styles['flex-raw']} ${styles['items-start']} ${styles['bg-top-layer']} ${styles['md:order-2']}  ${styles['overflow-y-scroll']}`}>
                                <div id="top" className={`${styles.flex} ${styles['w-full']} ${styles['flex-1']} ${styles['flex-col']} ${styles['items-center']} ${styles['justify-stretch']} ${styles['py-2']} ${styles['md:py-4']} ${styles['h-full']}`} style={{ width: '58%' }}>
                                    <MessageList />
                                </div>
                                {/* <div className={`${styles.flex} ${styles['w-half']} ${styles['flex-col']} ${styles['items-center']} ${styles['md:py-10']} ${styles['px-8']}`}> */}
                                <div className={`${styles['flex']} ${styles['flex-col']} ${styles['items-start']} ${styles['pr-12']}`} style={{ width: '42%', padding: '1rem 2rem 0rem .5rem', color: 'white' }}>
                                    <div style={{ marginBottom: '.6rem', padding: '.05rem .25rem .1rem', borderRadius: '4px' }} className={`${styles['bg-streaming-layer']}`}>
                                        <span style={{ fontSize: '.8rem', fontWeight: '500' }} className={`${styles['text-map-title']}`}>Streaming</span>
                                    </div>
                                    <HlsPlayer src={HLSIP} type="m3u8" playbackRate={playBackSpeed} />
                                    <MapDiv
                                        style={{
                                            width: '100%',
                                            height: '400px',
                                            marginTop: "1rem",
                                            overflow: "hidden",
                                            borderRadius: "6px",
                                        }}>
                                        <MyMap />
                                    </MapDiv>
                                </div>
                            </div>
                            <Header />
                        </div>
                    </div >
                </div>

            </main>
        </>
    );
}

export default HomePage;