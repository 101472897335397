import {create} from 'zustand';

const useMapStore = create((set) => ({
    center: {
        lat: 34.6111037, lng: 127.2056366
    },
    zoom: 17,
    setCenter: (center) => set({ center }),
    setZoom: (zoom) => set({ zoom }),
}));


export { useMapStore };