import Popup from "reactjs-popup";
import 'reactjs-popup/dist/index.css';
import styles from "../../../css/style.module.css";

import {Message} from "./message";

import {useDarkModeStore} from "../../../store/darkmodeStore";
import useObjectStore from "../../../store/objectStore";

import { useHLSIP } from "../../../store/HLSIPStore";
import useConfidenceStore from "../../../store/confidenceStore";

import './listPopup.css';
const ListComponent = (props) => {
    const { darkMode } = useDarkModeStore();
    const { HLSIP } = useHLSIP();

    const name = props.object ? props.object.name : "나사 Bolt";
    const confidence = props.object ? (props.object.confidence * 100).toFixed(2) : "32";
    const img_src = props.object ? `${HLSIP}/img/${props.object.url}` : '';
    return (
        <Popup trigger={
        <button 
        className={`${styles['duration-75']} ${styles['ease-out']} ${styles['enabled:hover:bg-wash']} ${styles.flex} ${styles['cursor-pointer']} ${styles['items-center']} ${styles['justify-between']} ${styles['gap-3']} ${styles['rounded-lg']} ${styles['py-3']} ${styles['px-3']} ${styles['text-body-md']} ${props.First ? styles['bg-secondary/50'] : ""}`}>
            <div className={`${styles.flex} ${styles['h-12']} ${styles['w-12']} ${styles['justify-center']} ${styles['rounded-md']} ${styles['bg-[length:90%]']} ${styles['bg-top']} ${styles['bg-no-repeat']} ${styles['p-2']} ${styles['bg-cover']} ${styles['bg-origin-content']} ${styles['px-1']} ${styles['pt-1']} ${styles['bg-design']}`}
                style={{
                    backgroundImage: `image-set(url('${img_src}') 1x)`, 
                    '--backgroundColor-design': '239 230 220',
                }} />
            <div className={`${styles.flex} ${styles['flex-1']} ${styles['flex-col']} ${styles['justify-center']} ${styles['text-left']}`} >
                <p className={`${styles['line-clamp-2']} ${styles['text-primary']} ${styles['text-subtitle-md']}`}>
                {props.object ? props.object.tid : '1'}.{name}
                </p>
                <p className={`${styles['line-clamp-1']} ${styles['text-body-sm']} ${styles['text-secondary']}`}>
                    {confidence}%  
                </p>
            </div>
        </button>} keepTooltipInside position={'right center'} className={`${styles['editor']}`}>
            {close => (
                <div style={{
                    padding: '2px',
                    backgroundColor: darkMode ? 'rgb(242, 242, 247)': 'rgb(28, 28, 30)' ,
                    borderRadius:'3px'}}>

                <Message key={props.key} object={props.object} dark={!darkMode}/>
                </div>
            
            )}
        </Popup>
    )
}

const Lists = () => {
    const { objectStore } = useObjectStore();
    const { confidence} = useConfidenceStore()
    return (
        <>
            {objectStore.map((object, index) => (
                object.confidence > confidence && <ListComponent key={index} First={index === 0} object={object} />
            ))}
        </>
    )
}

export {ListComponent, Lists};