import Popup from "reactjs-popup";
import 'reactjs-popup/dist/index.css';
import './ImagePopup.css';
const ImagePopup = (props) => {
    return (
        <Popup
            trigger={props.trigger}
            modal
            contentClassName="popup-content"
            overlayClassName="popup-overlay"
        >
            {close => (
                <div>
                    <img 
                        src={props.src} 
                        alt="" 
                        style={{ borderRadius: '3px', width: '100%', height: '100%' }} 
                    />
                </div>
            )}
        </Popup>
    );
}

export default ImagePopup;