
import styles from "../../../css/style.module.css";
import profileimg from "../../../imgs/profile.png";
import testimg from "../../../imgs/testimg.png";
import ImagePopup from "./popup/imagePopup";
import Markdown from 'react-markdown'
import remarkGfm from "remark-gfm";

import convert_timestamp from "../../../utils/timestamp";

import useObjectStore from "../../../store/objectStore";
import { useMapStore } from "../../../store/mapStore";

import { useHLSIP } from "../../../store/HLSIPStore";

import useConfidenceStore from "../../../store/confidenceStore";

const Message = (props) => {

    const { HLSIP } = useHLSIP();
    const { setCenter, setZoom } = useMapStore();

    const object = props.object;

    const name = object ? object.name : "나사 Bolt";
    const id = object ? object.tid : "1";
    const confidence = object ? (object.confidence * 100).toFixed(2) : "32";
    const lat = object ? (object.lat ? object.lat.toFixed(7) : '알 수 없음' ): "37.5665";
    const long = object ? (object.long ? object.long.toFixed(7) : '알 수 없음' ): "126.9780";
    const time = object ? convert_timestamp(object.timestamp) : "2021-09-01 12:00:00";
    const img_src = object ? `${HLSIP}/img/${object.url}` : testimg;

    let message = '';
    if(props.notdetected){
        message = '현재 공중에서 물체 탐지 중에 있습니다...'
    }
    else{
        message = ` **${name}** 가 **${id}** 번째 탐지되었습니다.  
    신뢰도 : **${confidence}%**  
    - **위도 (Lat)**: ${lat}  
    - **경도 (Lng)**: ${long}  
    **탐지 시간** : ${time}
    `
    }

    return (
        <div className={`${styles.flex} ${styles['w-full']} ${styles['flex-col']} ${styles['items-center']} ${styles['gap-1']} ${styles['py-2']} ${styles['md:py-3']} ${styles['duration-300']} ${styles['ease-out']} ${props.dark === true ?  styles['editor_dark'] : ""}`}>
            <div className={`${styles['max-w-thread']} ${styles.flex} ${styles['w-full']} ${styles['flex-col']} ${styles['items-center']} ${styles['gap-1']} ${styles['px-2']}`}>
                <div className={`${styles['gap-1']} ${styles['flex-col']} ${styles.flex} `}>
                    <div className={`${styles['md:p-message']} ${styles.relative} ${styles.flex} ${styles['justify-start']} ${styles['gap-2']} ${styles['px-4']}`}>
                        <div className={`${styles['md:absolute']} ${styles['md:left-0']}`}>
                            <a className={`${styles['cursor-pointer']}`} onClick={()=>{
                                setCenter({lat: lat, lng: long});
                                setZoom(25);
                            }} href="#top"> 
                                <img
                                alt="avatar profile for Example user"
                                // src="https://img.clerk.com/eyJ0eXBlIjoicHJveHkiLCJzcmMiOiJodHRwczovL2ltYWdlcy5jbGVyay5kZXYvb2F1dGhfZ29vZ2xlL2ltZ18ya3lSNVo0OUpIdXgwVDcwd014ZE00R1JsNkQifQ"
                                src={profileimg}

                                className={`${styles['rounded-full']} ${styles['h-10']} ${styles['w-10']}`}
                                referrerPolicy="no-referrer"
                            /></a>
                        </div>
                        <p className={`${styles['text-subtitle-lg']} ${styles['text-primary']}`}>KD-24 보라매</p>
                    </div>
                    <div className={`${styles['max-w-thread']} ${styles['md:p-message-2']} ${styles['text-body-lg']} ${styles.flex}  ${styles['items-start']}}`}>
                        <div className={`${styles['text-body-xmd']} ${styles['text-primary']}`}>
                            <div>
                                <div className={`${styles.markdown}`}>
                                    <Markdown remarkPlugins={[remarkGfm]}>
                                            {message}
                                        </Markdown>
                                </div>
                            </div>
                        </div>

                        {
                            props.notdetected ?  <></>:
                            <div className={`${styles['ml-12']} ${styles['text-body-xmd']} ${styles['text-primary']} `} style={{width: 'auto', maxWidth: '250px', minWidth:'100px'}}>                           
                        <ImagePopup src={img_src} trigger={<div className={`${styles['enabled:hover:bg-wash']} ${styles['cursor-pointer']} ${styles['flex']} ${styles['flex-col']} ${styles['items-start']}`}>
                            <img src={img_src} alt="" style={{ borderRadius: '5px', width: 'auto', height:'100%', maxHeight: '200px' }} />
                        </div>} />
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}


const MessageList = () => {

    const { objectStore } = useObjectStore();
    const { confidence} = useConfidenceStore();
    return (
        <div className={`${styles.flex} ${styles['w-full']} ${styles['flex-col']} ${styles['items-center']} ${styles['gap-1']} ${styles['py-2']} ${styles['md:py-1']}`}>                      
        {
            objectStore.length === 0 ?
                <Message notdetected={true} />
                :
            objectStore.slice(0, 100).map((object, index) => (
                object.confidence > confidence && <Message key={index} object={object} />
            ))
        }
        
        </div>
    );
}

export {Message, MessageList};


