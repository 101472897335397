import { NaverMap, Marker, useNavermaps, useMap } from 'react-naver-maps'
import { useEffect } from 'react';

import droneImage from "../imgs/pic3.png";

import useDroneStore from '../store/droneStore';
import useObjectStore from '../store/objectStore';

import styles from "../css/style.module.css";

import Swal from 'sweetalert2'

import convert_timestamp from "../utils/timestamp";

import testimg from "../imgs/testimg.png";

import { useHLSIP } from "../store/HLSIPStore";
import { useMapStore } from "../store/mapStore";


const Controll = () => {
  const { center, zoom } = useMapStore();

  const map = useMap()
  const navermaps = useNavermaps()

  useEffect(() => {
    map.setCenter(new navermaps.LatLng(center.lat, center.lng))
    map.setZoom(zoom)
  }, [center, zoom, map, navermaps])

  return <></>
}

const MyMap = () => {
  const navermaps = useNavermaps()

  const { center, setCenter, zoom, setZoom } = useMapStore();

  const { HLSIP } = useHLSIP();

  const { droneStore } = useDroneStore();
  const { objectStore } = useObjectStore();

  return (
    <>
      <div style={{ position: 'absolute', top: '0', left: '0', zIndex: "30", marginTop: '.6rem', marginLeft: '.5rem', padding: '.05rem .25rem .1rem', borderRadius: '4px' }} className={`${styles['bg-map-layer']} ${styles['cursor-pointer']}`}
        onClick={() => {
          setCenter({ lat: droneStore.lat, lng: droneStore.lng });
          setZoom(20);
        }}>
        <span style={{ fontSize: '.8rem', fontWeight: '500' }} className={`${styles['text-map-title']}`}>Drone Live</span>
      </div>
      <NaverMap
        defaultCenter={new navermaps.LatLng(center.lat, center.lng)}
        defaultZoom={zoom}
        defaultMapTypeId={navermaps.MapTypeId.SATELLITE}
        minZoom={16}
        maxZoom={60}
        center={new navermaps.LatLng(center.lat, center.lng)}
        // maxBounds={navermaps.LatLngBounds.bounds((new navermaps.LatLng(34.606310, 127.203245)), (new navermaps.LatLng(34.616541, 127.209333)))}
      >
        <Controll />
        <Marker
          position={new navermaps.LatLng(droneStore.lat, droneStore.lng)}
          icon={{
            content: `
<div style="
                        width: 36px; 
                        height: 36px; 
                        display: flex; 
                        align-items: center; 
                        justify-content: center; 
                        border-radius: 50%; 
                        background-color: #FFFFFF;
                    ">
                        <div style="
                            width: 33px;  /* 내부 요소의 크기를 조정 */
                            height: 33px; 
                            background: linear-gradient(to bottom, #7077D8 30%, #505AE0 60%);
                            border-radius: 50%; 
                            overflow: hidden;
                            display: flex; 
                            align-items: center; 
                            justify-content: center; 
                        ">
                            <img src="${droneImage}" alt="드론" style="width: 80%; height: 80%; border-radius: 50%; filter: invert(100%) brightness(200%);"  />
                        </div>
                    </div>
                    `,
            anchor: new navermaps.Point(18, 18) // 중심점이 마커의 중앙에 위치하도록 설정
          }}
        />

        {objectStore.map((object, index) => (
          <Marker
            position={new navermaps.LatLng(object.lat, object.long)}
            key={index}
            icon={{
              content: `
                            <div style="
                                width: 7px; 
                                height: 7px;
                                background-color: #FF0000; 
                                border-radius: 50%; 
                            ">
                            </div>
                        `,
              anchor: new navermaps.Point(3.5, 3.5) // 중심점이 마커의 중앙에 위치하도록 설정
            }}
            onClick={() => {
              Swal.fire({
                icon: 'info',
                title: `${object ? object.tid : '1'}번째 ${object ? object.name : '나사 Bolt'} 탐지 정보`,
                html: `
                          <div style="display: flex; flex-direction: column; align-items: center; gap: 1rem;">
                            <div style="display: flex; flex-direction: column; align-items: center; text-align: center;">
                              <img 
                                src="${object ? `${HLSIP}/img/${object.url}` : testimg}" 
                                alt="Detected Object" 
                                style="border-radius: 5px; width: auto; max-width: 500px; height: auto; max-height: 450px;" 
                              />
                            </div>
                            <div style="display: flex; flex-direction: column; align-items: left; gap: 1rem;">
                              <p><strong>ID:</strong> ${object ? object.tid : '1'}</p>
                              <p><strong>신뢰도:</strong> ${object ? (object.confidence * 100).toFixed(2) : '32'}%</p>
                              <p><strong>위도 (Lat):</strong> ${object ? (object.lat ? object.lat : '알 수 없음') : '37.5665'}</p>
                              <p><strong>경도 (Lng):</strong> ${object ? (object.long ? object.long : '알 수 없음') : '126.9780'}</p>
                              <p><strong>탐지 시간:</strong> ${object ? convert_timestamp(object.timestamp) : '2021-09-01 12:00:00'}</p>
                            </div>
                          </div>
                        `
              })

            }}
          />
        ))}
      </NaverMap>
    </>
  )
}

export default MyMap; 
