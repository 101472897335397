import { create } from 'zustand';

const useDroneStore = create((set) => ({
    droneStore: {
        altitude: null,
        distance: null,
        lat: 34.6111037,
        lng: 127.2056366,
        timestamp: null,
        yaw: null,
    },
    start: 0,
    setStart: (newStart) => set({ start: newStart }),
    setDroneStore: (newDroneStore) =>
        set((state) => ({
            droneStore: { ...state.droneStore, ...newDroneStore },
        })),
}));

export default useDroneStore;