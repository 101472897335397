import styles from "../../../../css/style.module.css";
import { IoSettingsOutline } from "react-icons/io5";
import Popup from "reactjs-popup";
import 'reactjs-popup/dist/index.css';
import styled from 'styled-components';
import {useDarkModeStore} from "../../../../store/darkmodeStore";
import {useHLSIP, usePlayBackSpeed} from "../../../../store/HLSIPStore";
import { useRef } from "react";
import useConfidenceStore from "../../../../store/confidenceStore";
import useWebSocketStore from "../../../../store/webSocketStore";

const SettingComponent = (props) => {
    const { darkMode } = useDarkModeStore();

    const { HLSIP, setHLSIP } = useHLSIP();
    const { playBackSpeed, setPlayBackSpeed } = usePlayBackSpeed();
    const { wsUrl, setWsUrl , ws} = useWebSocketStore();
    const { confidence, setConfidence } = useConfidenceStore();
    
    const hlsRef = useRef(null);
    const playbackSpeedRef = useRef(null);
    const wsUrlRef = useRef(null);
    const confidenceFilterRef = useRef(null);

    const StyledPopup = styled(Popup)`
        @keyframes anvil {
            0% {
                transform: scale(1) translateY(0px);
                opacity: 0;
                box-shadow: 0 0 0 rgba(241, 241, 241, 0);
            }
            1% {
                transform: scale(0.96) translateY(10px);
                opacity: 0;
                box-shadow: 0 0 0 rgba(241, 241, 241, 0);
            }
            100% {
                transform: scale(1) translateY(0px);
                opacity: 1;
                box-shadow: 0 0 500px rgba(241, 241, 241, 0);
            }
        }

        &-content {
            border-radius: 10px;
            padding: 20px;
            -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
            background: ${darkMode ? "rgb(28, 28, 30)" : "white"};
            border: ${darkMode ? "1px solid rgb(28, 28, 30)" : "1px solid rgb(241, 241, 241)"};
            max-width: 400px;
            width: 100%;
        }
        &-overlay {
            background: rgba(0, 0, 0, 0.5);
        }
    `;

    const inputStyle = {
        width: '100%',
        padding: '10px',
        borderRadius: '8px',
        border: darkMode ? '1px solid rgb(58, 58, 60)' : '1px solid rgb(200, 200, 200)',
        background: darkMode ? 'rgb(44, 44, 46)' : 'rgb(255, 255, 255)',
        color: darkMode ? 'rgb(255, 255, 255)' : 'rgb(0, 0, 0)',
        fontSize: '16px',
        marginBottom: '20px'
    };

    const labelStyle = {
        display: 'block',
        marginBottom: '8px',
        color: darkMode ? 'rgb(209, 209, 214)' : 'rgb(28, 28, 30)',
        fontWeight: '500',
    };

    const saveSetting = (close) => {
        setHLSIP(hlsRef.current.value);
        setPlayBackSpeed(parseFloat(playbackSpeedRef.current.value));
        setWsUrl(wsUrlRef.current.value);
        setConfidence(parseFloat(confidenceFilterRef.current.value));
        close();
    };

    return (
        <StyledPopup
            trigger={
                <button
                    style={{ width: "15%" }}
                    className={`${styles['flex']} ${styles['items-center']} ${styles['justify-center']} ${styles['gap-3']} ${styles['rounded']} ${styles['duration-75']} ${styles['ease-out']} ${styles['border']} ${styles["border-primary/[.13]"]} ${styles['bg-primary']} ${styles['text-primary']} ${styles['shadow-sm']} ${styles['enabled:hover:bg-wash']} ${styles['text-subtitle-md']} ${styles['h-8']} ${styles['my-3']}`}
                >
                    <IoSettingsOutline />
                </button>
            }
            modal
        >
            {close => (
                <div className={`${styles['rounded']}`}>
                    <h2 style={{ marginBottom: '20px', textAlign: 'center', color: darkMode ? 'rgb(209, 209, 214)' : 'rgb(28, 28, 30)' }}>대시보드 환경 설정</h2>

                    <label style={labelStyle} htmlFor="hls-ip">HLS Player IP Address</label>
                    <input ref={hlsRef} type="text" id="hls-ip" style={inputStyle} placeholder="Enter IP address" defaultValue={HLSIP}/>

                    <label style={labelStyle} htmlFor="websocket-url">WebSocket URL</label>
                    <input ref={wsUrlRef} type="text" id="websocket-url" style={inputStyle} placeholder="Enter WebSocket URL" defaultValue={wsUrl}/>


                    <label style={labelStyle} htmlFor="playback-speed">Playback Speed (0 - 1)</label>
                    <input ref={playbackSpeedRef} type="number" min='0.0' max='1.0' id="playback-speed" style={inputStyle} placeholder="0 ~ 1 사이 입력하세요." defaultValue={playBackSpeed}/>

                    <label style={labelStyle} htmlFor="confidence">신뢰도 필터링 (0 - 1)</label>
                    <input ref={confidenceFilterRef} type="number" min='0.0' max='1.0' style={inputStyle} placeholder="0 ~ 1 사이 입력하세요." defaultValue={confidence}/>    

                    <button onClick={()=>{
                        if(ws.readyState === 1){
                            ws.send(JSON.stringify({message: 'stop'}));
                        }
                        else{
                            alert('WebSocket is not connected');
                        }
                    }} style={{
                        marginTop: '20px',
                        padding: '10px',
                        borderRadius: '8px',
                        background: 'red',
                        color: 'white',
                        border: 'none',
                        cursor: 'pointer',
                        width: '100%',
                        fontSize: '16px',
                    }}>
                        탐사 정지
                        </button>         
                    <button onClick={()=>{
                        if(ws.readyState === 1){
                            ws.send(JSON.stringify({message: 'start'}));
                        }
                        else{
                            alert('WebSocket is not connected');
                        }
                    }} style={{
                        marginTop: '20px',
                        padding: '10px',
                        borderRadius: '8px',
                        background: 'green',
                        color: 'white',
                        border: 'none',
                        cursor: 'pointer',
                        width: '100%',
                        fontSize: '16px',
                    }}>
                        탐사 시작
                        </button>
                    <button
                        onClick={() => saveSetting(close)}
                        style={{
                            marginTop: '20px',
                            padding: '10px',
                            borderRadius: '8px',
                            background: darkMode ? 'rgb(88, 86, 214)' : 'rgb(0, 122, 255)',
                            color: 'white',
                            border: 'none',
                            cursor: 'pointer',
                            width: '100%',
                            fontSize: '16px',
                        }}
                    >
                        Save
                    </button>
                </div>
            )}
        </StyledPopup>
    );
};

export default SettingComponent;
