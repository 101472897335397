import { useRef, useEffect } from "react";
import useWebSocketStore from "../store/webSocketStore";
import useDroneStore from "../store/droneStore";
import useObjectStore from "../store/objectStore";

const WebSocketComponent = ()=> {
    const { wsUrl, setWebSocket } = useWebSocketStore();

    const { setDroneStore, setStart } = useDroneStore();
    const {  setObjectStore } = useObjectStore();

    const ws = useRef(null);
    useEffect(() => {

        // const ws = new WebSocket(wsUrl);

        ws.current = new WebSocket(wsUrl);
        setWebSocket(ws.current);
        ws.current.onopen = () => {
            console.log('WebSocket connected');
        };
        ws.current.onclose = () => {
            console.log('WebSocket disconnected');
        };
        ws.current.onerror = (error) => {
            console.error('WebSocket error', error);
        };
        ws.current.onmessage = (message) => {
            const data = JSON.parse(message.data);
            setDroneStore({
                altitude: data.drone.altitude,
                distance: data.drone.distance,
                lat: data.drone.lat,
                lng: data.drone.lon,
                timestamp: data.drone.timestamp,
                yaw: data.drone.yaw
            });
            // console.log(data.drone.lat, data.drone.lon);
            setStart(data.start);
            setObjectStore(data.objects.detections);
        };
        return () => {
            ws.current?.close();
        };
    }
    , [wsUrl, setWebSocket, setDroneStore, setObjectStore, setStart]);

    return (
        <>
        </>
    )
}

export default WebSocketComponent;